import React from 'react'
import { Helmet } from 'react-helmet'

import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const Head = () => {
  const { order } = useInvoiceContext()

  const TITLE = `Invoice | ${order?.bakery?.name}`
  const DESCRIPTION = 'Open link to view your order details and invoice.'
  const USER_AVATAR = order.bakery.user.avatar?.smallUrl

  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta name="robots" content="noindex" />
      <meta property="og:title" content={TITLE} />
      <meta name="description" content={DESCRIPTION} />
      <meta property="og:description" content={DESCRIPTION} />
      {USER_AVATAR && <meta property="og:image" content={USER_AVATAR} />}
    </Helmet>
  )
}
