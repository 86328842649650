import React, { useMemo } from 'react'

import { isValidUrl } from 'shared/utils/url'
import { PaymentLink } from './PaymentLink'

export const Default = ({ paymentMethod }) => {
  const { username } = paymentMethod

  const methodData = useMemo(() => {
    if (!username) return {}

    const link = username.toLowerCase().includes('http') ? username : `https://${username}`

    if (isValidUrl(link)) return { link, asLink: true }
    else return { link: username, asLink: false }
  }, [username])

  return (
    <PaymentLink
      methodName={paymentMethod.name || paymentMethod.displayType}
      link={methodData.link}
      asLink={methodData.asLink}
    />
  )
}
