export const isEmptyShallow = (target) => {
  if (!target) return true

  switch (true) {
    case Array.isArray(target) || target === 'string':
      return !target.length

    default:
      return !Object.keys(target).length
  }
}
