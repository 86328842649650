import React from 'react'
import styled from 'styled-components'
import { startsWith } from 'lodash'

import { Colors } from 'shared/styles/Colors'

const PROVIDER_URL = 'https://cash.app/$'
const PROVIDER_DOMAIN = 'cash.app'
const PROTOCOL = 'https://'

export const CashApp = ({ paymentMethod }) => {
  const username = paymentMethod.username

  const createLink = () => {
    if (!username) return null

    const data = username.toLowerCase()

    if (data.includes(PROVIDER_DOMAIN) && data.includes('http')) return data
    if (data.includes(PROVIDER_DOMAIN) && !data.includes('http')) return PROTOCOL.concat(data)
    if (startsWith(data, '@')) return PROVIDER_URL.concat(data.substring(1))
    if (startsWith(data, '$')) return PROVIDER_URL.concat(data.substring(1))
    else return PROVIDER_URL.concat(data)
  }

  return <PaymentLink methodName={paymentMethod.displayType} link={createLink()} />
}

const PaymentLink = styled.a({
  color: Colors.blue,
})
