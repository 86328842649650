import React, { memo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'

import { formatISODate } from 'shared/utils/dates'
import { Header } from 'web/components/Header/Header'
import { InvoiceProvider } from 'shared/contexts/InvoiceContext'
import { OrdersByDateDocument } from 'shared/graphql/queries/Order'
import { PrintInvoiceComponent } from 'web/components/Invoice/PrintInvoiceComponent'

export const PrintOrdersPage = memo(() => {
  const [searchParams, setSearchParams] = useSearchParams()

  const bakeryId = useRef(searchParams.get('id'))
  const date = useRef(searchParams.get('date'))

  useEffect(() => {
    if (bakeryId.current && date.current) setSearchParams('')
  }, [bakeryId, date, setSearchParams])

  const { data = { ordersByDate: [] } } = useQuery(OrdersByDateDocument, {
    fetchPolicy: 'cache-first',
    variables: { bakeryId: bakeryId.current, date: date.current },
    skip: !bakeryId.current && !date.current,
  })

  const orders = data.ordersByDate
  const bakery = orders[0]?.bakery

  const TITLE = `Orders by ${formatISODate(date.current)}`
  const DESCRIPTION = 'Print your Orders by date!'
  const USER_AVATAR = bakery?.user.avatar?.smallUrl

  useEffect(() => {
    if (orders.length) setTimeout(() => window.print(), 200)
  }, [orders])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={TITLE} />
        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
        {USER_AVATAR && <meta property="og:image" content={USER_AVATAR} />}
      </Helmet>

      <Container>
        <Header alt bakery={bakery} printOrder />
        <Spacer />
        {orders.map((order) => (
          <InvoiceProvider existingOrder={order} key={order.id}>
            <PrintInvoiceComponent />
          </InvoiceProvider>
        ))}
      </Container>
    </>
  )
})

const Container = styled.div({})

const Spacer = styled.div({ marginTop: 30 })
