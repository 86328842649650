import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollapse } from '@collapsed/react'

export const Collapsible = React.memo(
  ({ Header, children, expanded = false, containerStyle, headerStyle }) => {
    const [isExpanded, setIsExpanded] = useState(expanded)
    const handleToggling = () => setIsExpanded((ps) => !ps)

    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    return (
      <CollapsibleContainer style={containerStyle}>
        <HeaderContainer
          style={headerStyle}
          {...getToggleProps({
            onClick: handleToggling,
          })}
          isExpanded={isExpanded}
        >
          {Header}
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </HeaderContainer>
        <div {...getCollapseProps()}>{children}</div>
      </CollapsibleContainer>
    )
  },
)

const HeaderContainer = styled.div(
  ({ isExpanded }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  color: ${Colors.grey100};
  width: 100%;
  font-size: 1.8rem;
  margin-bottom: ${isExpanded ? '20px' : '0px'};
`,
)

const CollapsibleContainer = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${Colors.grey3};
  white-space: pre-line;
`
