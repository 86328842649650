import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faFileContract } from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Icon } from 'shared/components/Icon/Icon'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const ContractSection = ({ Container = React.Fragment }) => {
  const { order } = useInvoiceContext()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const attachment = useMemo(() => order.attachment, [order.attachment])
  const isImage = useMemo(() => {
    const arr = attachment.filename.split('.')
    return !['doc', 'docx', 'pdf'].includes(arr[arr.length - 1])
  }, [attachment])

  const handleShow = () => {
    if (isImage) return navigate(`/contracts/${order.orderNumber}`)
    window.open(attachment.fullUrl, '_blank')
  }

  const handleOpen = () => setOpen((prev) => !prev)

  return (
    <Container>
      <ListItem onClick={handleOpen}>
        <ListItemGroup>
          <Icon icon={faFileContract} size={24} />
          <ListItemText>View Attachment</ListItemText>
        </ListItemGroup>

        {open ? <Icon icon={faChevronDown} /> : <Icon icon={faChevronRight} />}
      </ListItem>

      {open && (
        <AttachmentItem onClick={handleShow}>
          <AttachmentName>{attachment.filename}</AttachmentName>
        </AttachmentItem>
      )}
    </Container>
  )
}

export const ListItem = styled.div({
  cursor: 'pointer',
  display: 'flex',
  color: Colors.infoAlertTextColor,
  backgroundColor: Colors.infoAlternativeBgColor,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 24px',
})

export const ListItemText = styled(Cross.Text)({
  fontSize: 18,
  paddingLeft: 16,
  fontWeight: 600,
})

export const ListItemGroup = styled.div({
  flexDirection: 'row',
  alignItems: 'center',
  paddingHorizontal: 4,
  justifyContent: 'flex-start',
})

export const AttachmentItem = styled.div({
  display: 'flex',
  color: Colors.brand,
  flexDirection: 'row',
  marginLeft: 20,
  padding: '6px 12px',
})

export const AttachmentName = styled.span({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})
