import React, { useState } from 'react'
import styled from 'styled-components'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { BackdropComponent } from './Backdrop'
import { bakeryPath } from 'shared/utils/bakeries'
import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer } from 'web/styles/responsive'
import { hasUnreadSelector } from 'shared/reducers/messenger'
import { headerHeight } from 'web/styles/Layout'
import { Icon } from 'shared/components/Icon/Icon'
import { Sidebar } from './Sidebar'
import { Title } from './Title'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

const HeaderContainer = ({ children, ...props }) => (
  <Container bgColor={props.bgColor}>
    <Inner {...props}>{children}</Inner>
  </Container>
)

export const Header = React.memo(({ alt, bakery }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const user = useCurrentUser()
  const { isMdUp } = useResponsiveLayout()
  const hasUnread = useSelector(hasUnreadSelector)
  const themeContext = useThemeContext()
  const { dividerVariant, primaryColor } = themeContext || {}

  if (!bakery) return <Container centeralized />

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const LinkedAvatar = (
    <AvatarContainer to={bakeryPath(bakery)}>
      <UserAvatar user={bakery.user} bakery={bakery} size={50} />
    </AvatarContainer>
  )

  if (alt) {
    return (
      <HeaderContainer centeralized bgColor={dividerVariant > 0 ? primaryColor : undefined}>
        <AvatarContainer to={bakeryPath(bakery)}>
          <UserAvatar bakery={bakery} user={bakery.user} />
          <BakeryName>{bakery.name}</BakeryName>
        </AvatarContainer>
      </HeaderContainer>
    )
  }

  return (
    <HeaderContainer isMdUp={isMdUp} bgColor={dividerVariant > 0 ? primaryColor : undefined}>
      {!bakery && <Title />}
      {bakery && LinkedAvatar}

      <Actions>
        {user && (
          <ChatLink to="/chat">
            {hasUnread && <UnreadMessages />}
            <ChatIcon icon={faComments} color={Colors.blue} size="lg" />
          </ChatLink>
        )}

        {bakery && (
          <HamburgerIcon icon={faBars} color={Colors.black} size={40} onClick={toggleSidebar} />
        )}

        {bakery && (
          <>
            <Sidebar
              isVisible={isSidebarOpen}
              Avatar={LinkedAvatar}
              onClick={toggleSidebar}
              isMdUp={isMdUp}
            />

            <BackdropComponent isVisible={isSidebarOpen} onClick={toggleSidebar} />
          </>
        )}
      </Actions>
    </HeaderContainer>
  )
})

const HamburgerIcon = styled(Icon)`
  position: relative;
  top: 5px;
`

const ChatIcon = styled(Icon)`
  margin-left: 30px;
`

const Actions = styled.div`
  display: flex;
  position: relative;
  bottom: 4px;
`

const Inner = styled(FixedWidthContainer)(
  ({ isMdUp, centeralized }) => `
    display: flex;
    align-items: center;
    height: ${headerHeight}px;
    padding: ${isMdUp ? '0px 50px' : '0 30px'};
    justify-content: ${centeralized ? 'center' : 'space-between'};
  `,
)

const Container = styled.div(
  ({ bgColor }) => `
    background-color: ${bgColor || Colors.white};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
    z-index: 9;
  `,
)

const ChatLink = styled(Link)`
  display: block;
  position: relative;
  width: 80px;
  top: 15px;
`

const UnreadMessages = styled.div`
  background-color: ${Colors.red};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: -1px;
  right: -1px;
`

const AvatarContainer = styled(Link)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const BakeryName = styled.div`
  font-size: 14px;
  margin: 5px 0px 0px 0px;
`
