import React, { useState } from 'react'
import styled from 'styled-components'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollapse } from '@collapsed/react'

import { Colors } from 'shared/styles/Colors'
import { fontSizeAdjustments } from 'shared/styles/PremiumThemes'

const OfferingsList = ({
  array,
  title,
  primaryColor,
  backgroundColor,
  text,
  defaultExpanded = false,
  primaryFont,
}) => {
  const [isExpanded, setExpanded] = useState(defaultExpanded)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  if (!array?.length && !text?.length) return null

  const renderItem = (item) => (
    <Offering primaryColor={primaryColor} backgroundColor={backgroundColor} key={item.id ?? item}>
      {item.name ?? item}
    </Offering>
  )

  return (
    <CollapsibleContainer>
      <HeadingContainer
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        <Heading font={primaryFont}>{title}</Heading>
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
      </HeadingContainer>

      <ItemContainer {...getCollapseProps()}>{text || array.map(renderItem)}</ItemContainer>
    </CollapsibleContainer>
  )
}

export default OfferingsList

const CollapsibleContainer = styled.div`
  margin-bottom: 18px;
`

const ItemContainer = styled.div`
  margin-left: 20px;
  margin-top: 30px;
  font-weight: 500;
  white-space: pre-wrap;
`

const Offering = styled.span(
  ({ primaryColor, backgroundColor }) => `
  background-color: ${backgroundColor};
  color: ${primaryColor};
  padding: 5px 10px;
  font-size: 1.6rem;
  margin-right: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: inline-block;
  max-width: calc(100vw - 80px);
  word-break: break-word;
`,
)

const HeadingContainer = styled.div(
  ({ isExpanded }) => `
  padding: 10px;
  border-bottom: 2px solid ${Colors.grey10};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${isExpanded ? '20px' : '0px'};
  cursor: pointer;
`,
)

const Heading = styled.h3(
  ({ font }) => `
letter-spacing: 0.3px;
font-size: ${2 + (fontSizeAdjustments[font] || 0)}rem;
font-weight: 600;
color: black;
margin: 0;
`,
)
