import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { Fragments } from 'shared/constants/Fragments'
import { login } from 'shared/reducers/account'
import { notifyAboutManagement } from 'shared/utils/bakeries'

const CREATE_SESSION = gql`
  ${Fragments.CurrentUser}

  mutation CreateSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      accepted
      user {
        ...CurrentUserFragment
      }
      jwt
      refreshToken
      errors
    }
  }
`

export const useSignIn = ({ email, password, afterSignIn, tfaToken, handleTwoFactorRequest }) => {
  const dispatch = useDispatch()
  const [createSessionMutation] = useMutation(CREATE_SESSION)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const submit = async () => {
    if (loading) return
    setLoading(true)
    setError(null)

    try {
      const {
        data: {
          createSession: { user, jwt, refreshToken, errors, accepted },
        },
      } = await createSessionMutation({
        variables: { input: { email, password, tfaToken } },
      })

      if (errors.length) {
        setError(errors[0])
      } else {
        if (accepted) {
          setTimeout(() => setLoading(false), 500)
          return handleTwoFactorRequest()
        }

        await afterSignIn?.(user)
        dispatch(login({ user, jwt, refreshToken }))
        notifyAboutManagement({ user, dispatch })
      }
    } catch (e) {
      console.log('Unknown error signing in.', e)
      setError('Unknown error signing in.')
    }

    setLoading(false)
  }

  return { submit, loading, error }
}
