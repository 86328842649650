import React, { memo, useCallback, useState } from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { formatCents } from 'shared/utils/currency'
import { ScrollIndicator } from 'web/components/ScrollIndicator'

const scrollIndicatorStyle = {
  position: 'absolute',
  right: 20,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 'auto',
}

export const FeaturedOfferings = memo(({ offerings, logo, currency, onItemClick, isMdUp }) => {
  const [scrolled, setScrolled] = useState(false)

  const handleOnScroll = useCallback(({ target }) => {
    setScrolled(target.scrollLeft !== 0)
  }, [])

  return (
    <Container onScroll={handleOnScroll}>
      {offerings.map(({ id, image, name, priceCents, slug }) => (
        <Offering key={id} onClick={onItemClick(slug)}>
          <OfferingImage bgImg={`url(${image || logo})`} />
          <OfferingContent>
            <NameAndPrice>
              <Name>{name}</Name>
              {priceCents > 0 && <Price>{formatCents(priceCents, currency)}</Price>}
            </NameAndPrice>
            <Note>click for description</Note>
          </OfferingContent>
        </Offering>
      ))}
      {!isMdUp && !scrolled && offerings.length > 3 && (
        <ScrollIndicator direction="right" style={scrollIndicatorStyle} />
      )}
    </Container>
  )
})

const Container = styled.div({
  margin: '0 -30px -10px',
  padding: '0 20px 10px',
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  gap: 15,
  position: 'relative',
})

const Offering = styled.div({
  width: 110,
  height: 'auto',
  flex: 'none',
  border: `1px solid ${Colors.grey25}`,
  borderRadius: '5%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
})

const OfferingImage = styled.div(({ bgImg }) => ({
  height: 75,
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundImage: bgImg,
  backgroundSize: 'cover',
  backgroundPosition: '50%',
}))

const OfferingContent = styled.div({
  padding: '15px 8px 10px',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'space-between',
  flex: 1,
})

const NameAndPrice = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

const StyledP = styled.p({
  margin: 0,
  lineHeight: 1,
})

const Name = styled(StyledP)({
  fontSize: 14,
  fontWeight: 600,
})
const Price = styled(StyledP)({
  fontSize: 13,
})
const Note = styled(StyledP)({
  fontSize: 10,
  color: Colors.grey75,
  textAlign: 'center',
})
