import React, { useMemo } from 'react'

import { PaymentLink } from './PaymentLink'
import { validateEmail } from 'shared/utils/email'

const PROVIDER_URL = 'https://paypal.me/'
const PROVIDER_DOMAIN = 'paypal.me'
const PROTOCOL = 'https://'

export const PayPal = ({ paymentMethod }) => {
  const username = paymentMethod.username?.toLowerCase()

  const createLink = (data) => {
    if (data.includes(PROVIDER_DOMAIN) && data.includes('http')) return data
    if (data.includes(PROVIDER_DOMAIN) && !data.includes('http')) return PROTOCOL.concat(data)
    else return PROVIDER_URL.concat(data)
  }

  const isMail = useMemo(() => {
    if (!username) return null

    if (validateEmail(username)) return true
    else return false
  }, [username])

  return (
    <PaymentLink
      methodName={paymentMethod.displayType}
      link={isMail ? username : createLink(username)}
      isMail={isMail}
    />
  )
}
