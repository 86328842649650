import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useFragment } from '@apollo/client'

import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer, MediaQueries } from 'web/styles/responsive'
import { GalleryImageFragment } from 'shared/graphql/fragments'
import { MainSubtitle } from './Details'
import { setLightboxImage } from 'shared/reducers/ui'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const ImageWidthMap = {
  md: '33.33%',
  lg: '25%',
}

const GalleryImage = ({ imageId }) => {
  const { currentSize } = useResponsiveLayout()
  const dispatch = useDispatch()

  const { data } = useFragment({
    fragment: GalleryImageFragment,
    fragmentName: 'GalleryImage',
    from: {
      __typename: 'GalleryImage',
      id: imageId,
    },
  })

  return (
    <ImageWrapper key={data.id} width={ImageWidthMap[currentSize] || '50%'}>
      <Image src={data.thumbnailUrl} onClick={() => dispatch(setLightboxImage(data.fullUrl))} />
    </ImageWrapper>
  )
}

export const Gallery = () => {
  const { isMdUp } = useResponsiveLayout()
  const { layoutHeight } = useWindowDimensions()
  const { primaryFont } = useThemeContext()
  const { bakery } = useBakeryContext()

  return (
    <>
      <Helmet>
        <title>Gallery | {bakery.name}</title>
      </Helmet>

      <Container minHeight={layoutHeight}>
        <MainColumn isMdUp={isMdUp}>
          <MainSubtitle font={primaryFont}>Gallery</MainSubtitle>
          <Images>
            {bakery.galleryImages?.map((image) => (
              <GalleryImage key={image.id} imageId={image.id} />
            ))}
          </Images>
        </MainColumn>
      </Container>
    </>
  )
}
const Container = styled(FixedWidthContainer)(
  ({ minHeight }) => `
    margin-bottom: 40px;
    padding-top: 5px;
    border-radius: 4px;
    background-color: ${Colors.white};
    min-height: ${minHeight - 34}px;

    @media ${MediaQueries.mdUp} {
      margin-top: 30px;
      min-height: ${minHeight - 64}px;
    }
  `,
)

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`

const ImageWrapper = styled.div`
  padding: 5px;
  width: ${(props) => props.width};
`

const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  background-color: ${Colors.gre25};
  cursor: pointer;
`

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
`
