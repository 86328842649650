import React, { memo } from 'react'
import styled from 'styled-components'

import {
  BakerySection,
  CommentSection,
  ContractSection,
  ImageSection,
  InvoiceNoteSection,
  ItemSection,
  NoteSection,
  QuestionSection,
  ScheduleSection,
} from '.'
import { ContractContainer, SectionContainer } from 'shared/components/Invoice/styles'
import { CostSection, CustomerSection, StatusSection } from 'shared/components/Invoice'
import { FixedWidthContainer } from 'web/styles/responsive'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const PrintInvoiceComponent = memo(() => {
  const { order } = useInvoiceContext()

  const isFinalized = order?.subtotalAmountCents !== null

  if (!order) return null

  const { attachment, isInstantCheckout, bakerOrderDetails } = order

  return (
    <Container>
      <SectionContainer $padding={10}>
        <Col>
          <MainColumn>
            {!!attachment && <ContractSection Container={ContractContainer} />}
            <InvoiceNoteSection show Container={SectionContainer} />
            <CostSection show={isFinalized} Container={SectionContainer} printOrder />
            {!isInstantCheckout && (
              <ScheduleSection show={isFinalized} Container={SectionContainer} printOrder />
            )}
          </MainColumn>

          <SecondaryColumn>
            <CustomerSection Container={SectionContainer} printOrder />
            <NoteSection show={!!bakerOrderDetails} Container={SectionContainer} />

            <SectionContainer $marginBottom={0}>
              <BakerySection show />
              <StatusSection show printOrder />
              <ItemSection show printOrder />
              <ImageSection show />
              <QuestionSection show />
              <CommentSection show />
            </SectionContainer>
          </SecondaryColumn>
        </Col>
      </SectionContainer>
    </Container>
  )
})

const Container = styled(FixedWidthContainer)({
  '&:not(:last-child)': {
    pageBreakAfter: 'always',
  },
})

const Col = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const MainColumn = styled.div({
  flex: '60%',
  padding: '0 5px',
})

const SecondaryColumn = styled.div({
  flex: '40%',
  padding: '0 5px',
})
