import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'

import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { Icon } from 'shared/components/Icon/Icon'

export const PaymentLink = memo(
  ({ methodName, link, isMail = false, copy = false, asLink = true }) => {
    const [copied, setCopied] = useState(false)

    useEffect(() => {
      if (copied) setTimeout(() => setCopied(false), 3000)
    }, [copied])

    const handleCopy = () => {
      setCopied(true)
      navigator.clipboard.writeText(link)
    }

    return (
      <Container>
        <MethodName>
          {methodName}
          {link && ':'}
        </MethodName>

        {link && asLink ? (
          <Link href={isMail ? `mailto:${link}` : link} copy={copy}>
            {link}
          </Link>
        ) : (
          <Method copy={copy}>{link}</Method>
        )}
        {copy && (
          <CopyButton type="button" onClick={handleCopy}>
            <Icon icon={copied ? faCheck : faCopy} size={25} />
          </CopyButton>
        )}
      </Container>
    )
  },
)

const linkCommons = (copy) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: copy ? '45%' : '50%',
})

const Link = styled.a(({ copy }) => ({
  color: Colors.blue,
  ...linkCommons(copy),
}))

const Method = styled.div(({ copy }) => ({
  ...linkCommons(copy),
}))

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  alignItems: 'center',
})

const CopyButton = styled(Button)({
  width: 40,
  height: 40,
  backgroundColor: 'transparent',
  color: Colors.brand,
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const MethodName = styled.div({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '50%',
})
