import React from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, useRoutes } from 'react-router-dom'

import { PAGES } from './constants'
import { useOrderContext } from 'web/contexts/OrderContext'

const Navigation = (props) =>
  useRoutes([
    ...PAGES.map(({ name: path, component: Component }) => ({
      path,
      element: <Component {...props} />,
    })),
    {
      path: '/',
      element: <Navigate to="/" replace />,
    },
  ])

export const OrderRequestPage = () => {
  const { order } = useOrderContext()

  if (order.state !== 'customerBuilding')
    return <Navigate to={`/orders/${order.orderNumber}`} replace />

  return (
    <>
      <Helmet>
        <title>Order Request | {order?.bakery.name}</title>
      </Helmet>

      <Navigation bakerySlug={order?.bakery?.slug} />
    </>
  )
}
