import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

import { useQuestionCounter } from 'web/hooks/useQuestionCounter'

export const useOrderBuild = ({ order }) => {
  const location = useLocation()
  const currentPage = location.pathname.split('/').pop()
  const { enableOrderSpecifications } = order?.bakery || {}
  const { orderItems = [] } = order || {}

  const { skipDueDate, skipInspirationPhotos } = useMemo(() => {
    return {
      skipDueDate: orderItems.every((oi) => oi.offering.dueDateDisabled),
      skipInspirationPhotos: orderItems.every((oi) => !oi.offering.enableInspirationPhotos),
    }
  }, [orderItems])

  const customQuestions = useMemo(() => {
    const items = order.bakery?.customQuestions

    if (!items?.length || currentPage === 'details') return []

    return items.reduce((acc, cq) => {
      // If custom question has no categories and offerings or has offerings but not applied separately
      // it means that either it's a general question or that it's for other category/offering not in the Order
      if (cq.offerings.length === 0 && cq.categories.length === 0) acc.push(cq)
      else {
        // here we're building array of order items that we need to show questions for
        const questionOfferings = orderItems.reduce((oiAcc, oi) => {
          if (cq.categories.some((cc) => cc.id === oi.categoryId)) oiAcc.push(oi)
          if (cq.offerings.some((co) => co.id === oi.offeringId)) oiAcc.push(oi)
          return oiAcc
        }, [])

        questionOfferings.forEach((_, idx) => acc.push({ ...cq, orderItemIdx: idx }))
      }

      return acc
    }, [])
  }, [order, currentPage, orderItems])

  const customQuestionsLength = customQuestions.length
  const dueDateOffset = skipDueDate ? 0 : 1
  const specificationsOffset = enableOrderSpecifications ? 0 : 1
  const inspirationalPhotosOffset = skipInspirationPhotos ? 1 : 0

  const { questionCounter, setQuestionCounter } = useQuestionCounter({
    customQuestionsLength,
    dueDateOffset,
    specificationsOffset,
    currentPage,
    inspirationalPhotosOffset,
  })

  return {
    currentPage,
    customQuestions,
    loading: order?.bakery?.status === 'expired',
    order,
    questionCounter,
    setQuestionCounter,
    skipDueDate,
  }
}
