import React, { memo } from 'react'
import styled from 'styled-components'
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons'

import { Colors } from 'shared/styles/Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const directions = {
  up: faChevronUp,
  down: faChevronDown,
  left: faChevronLeft,
  right: faChevronRight,
}

export const ScrollIndicator = memo(({ direction = 'down', style }) => {
  return (
    <ScrollIndicatorContainer style={style}>
      <ScrollIndicatorCircle>
        <FontAwesomeIcon icon={directions[direction]} color={Colors.grey75} />
      </ScrollIndicatorCircle>
    </ScrollIndicatorContainer>
  )
})

const ScrollIndicatorContainer = styled.div`
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 9999;
`

const ScrollIndicatorCircle = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
`
