import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import shoppingCart from 'shared/images/shopping-cart.png'
import { Collapsible } from 'shared/components/Collapsible'
import { Colors } from 'shared/styles/Colors'
import { Header } from 'web/pages/OrderRequest/styles'
import { Offering } from './Offering'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { PresaleItem } from './PresaleItem'
import { useOfferingsByCategory } from 'shared/hooks/useOfferingsByCategories'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const BakedGoods = ({ bakerySlug }) => {
  const { state } = useLocation()
  const { bakery, order } = useOrderContext()
  const { loading, updateOrder } = useUpdateOrder()

  useEffect(() => {
    //  Pre-load the image, so it won't pop-up when Customer adds something to the cart.
    new Image().src = shoppingCart
  }, [])

  const allItems = useMemo(
    () => [...bakery.selectedPresaleItems, ...bakery.selectedMenuItems],
    [bakery.selectedPresaleItems, bakery.selectedMenuItems],
  )

  const defaultOrderItems = useMemo(
    () =>
      order.orderItems.map((item) => ({
        offeringId: item.offering.id,
        quantity: item.quantity,
        unit: item.unit,
        spec: item.spec,
      })),
    [order],
  )

  const stateItems = useMemo(() => {
    const selectedItems = [
      ...bakery.selectedPresaleItems.filter((item) => item.id === state?.itemId),
      ...bakery.selectedMenuItems.filter((item) => item.id === state?.itemId),
    ]

    return selectedItems.map((item) => ({
      offeringId: item.id,
      quantity: 0,
    }))
  }, [state, bakery.selectedMenuItems, bakery.selectedPresaleItems])

  const [orderItems, setOrderItems] = useState([...defaultOrderItems, ...stateItems])

  const onNext = ({ navigateToNextScreen }) => {
    updateOrder({ input: { orderItems } }, navigateToNextScreen)
  }

  const toggleOrderItem = useCallback((offeringId) => {
    setOrderItems((prevOrderItems) => {
      const exists = prevOrderItems.some((oi) => oi.offeringId === offeringId)

      if (exists) {
        return prevOrderItems.filter((item) => item.offeringId !== offeringId)
      } else {
        return [...prevOrderItems, { offeringId, quantity: 0 }]
      }
    })
  }, [])

  const selectedItems = useMemo(
    () => allItems.filter((itm) => orderItems.some((oi) => oi.offeringId === itm.id)),
    [orderItems, allItems],
  )

  const itemsByCategory = useOfferingsByCategory(allItems, selectedItems)

  const selectedBakedGoods = useMemo(
    () =>
      bakery.selectedBakedGoods.filter((itm) => orderItems.some((oi) => oi.offeringId === itm.id)),
    [orderItems, bakery.selectedBakedGoods],
  )

  const allBakedGoods = useMemo(
    () => bakery.selectedBakedGoods.filter((bg) => !selectedBakedGoods.includes(bg)),
    [selectedBakedGoods, bakery.selectedBakedGoods],
  )

  const cartNotEmpty = !!(selectedItems.length || selectedBakedGoods.length)

  return (
    <OrderRequestLayout
      nextDisabled={!orderItems.length || loading}
      onNext={onNext}
      bakerySlug={bakerySlug}
    >
      <Header>
        {cartNotEmpty ? (
          <>
            Your Cart
            <img src={shoppingCart} />
          </>
        ) : (
          'What would you like to order?'
        )}
      </Header>

      <OffersContainer>
        {selectedItems.map((offering) => (
          <PresaleItem
            currencyId={bakery.currency.id}
            isInstantCheckout={!!bakery.instantCheckoutMethod}
            key={offering.slug}
            offeringSlug={offering.slug}
            onClick={toggleOrderItem}
            selected
            userId={bakery.user.id}
          />
        ))}

        {selectedBakedGoods.map((offering) => (
          <Offering key={offering.id} offeringId={offering.id} onClick={toggleOrderItem} selected />
        ))}

        {cartNotEmpty ? <Header>Add to your order</Header> : ''}

        {Object.keys(itemsByCategory)
          .sort()
          .map((categoryKey, categoryIdx) => (
            <Collapsible
              key={categoryKey}
              expanded={categoryIdx === 0}
              Header={itemsByCategory[categoryKey][0].category?.name || 'Uncategorized'}
              headerStyle={CollapsibleHeaderStyle}
              containerStyle={{
                padding: '15px 0 0',
                marginBottom: categoryIdx + 1 === Object.keys(itemsByCategory).length ? 20 : 0,
              }}
            >
              {itemsByCategory[categoryKey].map((offering, offeringIdx) => (
                <PresaleItem
                  currencyId={bakery.currency.id}
                  isInstantCheckout={!!bakery.instantCheckoutMethod}
                  key={offering.id}
                  marginTop={offeringIdx === 0 ? 20 : undefined}
                  offeringSlug={offering.slug}
                  onClick={toggleOrderItem}
                  userId={bakery.user.id}
                />
              ))}
            </Collapsible>
          ))}

        {!allItems.length &&
          allBakedGoods.map((offering) => (
            <Offering
              selected={false}
              key={offering.id}
              offeringId={offering.id}
              onClick={toggleOrderItem}
            />
          ))}
      </OffersContainer>
    </OrderRequestLayout>
  )
}

const OffersContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexDirection: 'column',
})

const CollapsibleHeaderStyle = {
  marginBottom: 0,
  fontSize: '1.8rem',
  paddingBottom: 14,
  borderBottomWidth: 2,
  borderColor: Colors.grey25,
  borderBottomStyle: 'solid',
}
