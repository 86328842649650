import styled from 'styled-components'

import { MediaQueries } from 'web/styles/responsive'

export const Header = styled.h1({
  fontWeight: 'bold',
  fontSize: '2.5rem',
  marginBottom: '1em',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& img': {
    height: '2.5rem',
    marginLeft: 10,
  },

  [`@media ${MediaQueries.mdUp}`]: {
    fontSize: '3rem',

    '& img': {
      height: '3rem',
    },
  },
})

export const HelpText = styled.div({
  color: 'black',
  fontSize: '3vw',
  fontWeight: 500,

  [`@media ${MediaQueries.smUp}`]: {
    fontSize: '12px',
  },
})
