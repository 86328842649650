import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useQuestionCounter = ({
  customQuestionsLength,
  dueDateOffset,
  specificationsOffset,
  currentPage,
  inspirationalPhotosOffset,
}) => {
  const [questionCounter, setQuestionCounter] = useState(0)
  const [searchParams] = useSearchParams()
  // We're using this only when getting back from Pictures to Questionnaire
  const isBack = searchParams.get('back')

  useEffect(() => {
    const initialCounter =
      {
        theme: 3,
        questionnaire: isBack ? 4 + customQuestionsLength - 1 : 4,
        pictures: 4 + customQuestionsLength,
        terms: 5 + customQuestionsLength - inspirationalPhotosOffset,
      }[currentPage] || 3
    setQuestionCounter(initialCounter + dueDateOffset - specificationsOffset)
  }, [
    customQuestionsLength,
    currentPage,
    dueDateOffset,
    specificationsOffset,
    inspirationalPhotosOffset,
    isBack,
  ])

  return { questionCounter, setQuestionCounter }
}
