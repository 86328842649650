import React from 'react'
import styled from 'styled-components'

import { formatCents } from 'shared/utils/currency'
import { ItemRow, Label, Row, Strong } from './styles'
import { plus } from 'shared/utils/number'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const CostSection = ({ show, Container = React.Fragment, printOrder }) => {
  const { order } = useInvoiceContext()

  if (!show) return null

  return (
    <Container>
      <Row $marginBottom={20}>
        <Strong $fontSize={printOrder ? 24 : undefined}>Order # {order.orderNumber}</Strong>
      </Row>

      <ItemRow>
        <Label>
          <Strong>Subtotal</Strong>
        </Label>

        <Value>{formatCents(order.subtotalAmountCents, order?.bakery?.currency)}</Value>
      </ItemRow>

      {!!order.totalTipCents && (
        <ItemRow>
          <Label>
            <Strong>Tips total</Strong>
          </Label>

          <Value>{formatCents(order.totalTipCents, order?.bakery?.currency)}</Value>
        </ItemRow>
      )}

      {order.orderAdjustments &&
        order.orderAdjustments.map((orderAdjustment) => {
          const isDiscount = orderAdjustment.adjustment.adjustmentCategory === 'discount'
          return (
            <ItemRow key={orderAdjustment.id}>
              <Label>
                <Strong>{orderAdjustment.adjustment.name}</Strong>
              </Label>

              <Value>
                {(isDiscount ? '(–' : '') +
                  formatCents(orderAdjustment.amountCents, order?.bakery?.currency) +
                  (isDiscount ? ')' : '')}
              </Value>
            </ItemRow>
          )
        })}

      {Number.isInteger(order.totalAmountCents) && (
        <FinalRow $marginTop={10}>
          <Label>
            <Strong>Total</Strong>
          </Label>

          <Value>
            <Strong>
              {formatCents(
                plus(order.totalAmountCents, order.totalTipCents),
                order?.bakery?.currency,
              )}
            </Strong>
          </Value>
        </FinalRow>
      )}
    </Container>
  )
}

const FinalRow = styled(ItemRow)({
  fontSize: 21,
})

const Value = styled.p({
  margin: 0,
})
