import React from 'react'

import { PaymentLink } from './PaymentLink'

export const BankTransfer = ({ paymentMethod }) => {
  return (
    <PaymentLink
      methodName={paymentMethod.displayType}
      link={paymentMethod.username}
      asLink={false}
      copy
    />
  )
}
